import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import Markdown from "markdown-to-jsx";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import React from "react";
import { YOUTUBE_CHANNEL_URL } from "../constants";
import { getArticleLink } from "../helper/link";
import SEO from "../modules/seo";

const NewsletterDetail = ({ data: { newsletter, images }, pageContext }) => {
  const { name, settings, breadcrumbs, showBreadcrumbNavigation } = pageContext;
  const siteSettings = settings;
  const { footerAddress } = siteSettings;
  const url =
    process.env.NODE_ENV === "development"
      ? "https://staging.frient.cuisines.io"
      : "https://www.frient.de";

  const generateLink = link => url + link;
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p
          style={{
            height: "17px",
            lineHeight: "17px",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          {children}
        </p>
      ),
    },
  };

  images = images.edges.map(edge => edge.node);

  let {
    number,
    headline,
    body: { body },
    image,
    impulsArticle,
    stimmenArticles,
    themenArticles,
    tippsArticles,
  } = newsletter;

  impulsArticle = {
    link: getArticleLink(impulsArticle.title, settings.node_locale),
    ...impulsArticle.articleDetail,
  };

  stimmenArticles = stimmenArticles.map(general => ({
    link: getArticleLink(general.title, settings.node_locale),
    ...general.articleDetail,
  }));

  themenArticles = themenArticles.map(general => ({
    link: getArticleLink(general.title, settings.node_locale),
    ...general.articleDetail,
  }));

  tippsArticles = tippsArticles.map(general => ({
    link: getArticleLink(general.title, settings.node_locale),
    ...general.articleDetail,
  }));

  const renderThemenArticle = ({
    title,
    reader,
    link,
    topic,
    organization,
    isLeft,
    isRight,
    isLast,
  }) => {
    return (
      <>
        <td style={{ width: isRight ? "15px" : 0 }}></td>
        <td
          style={{
            height: "inherit",
            verticalAlign: "top",
            borderBottom: isLast ? "none" : "1px solid #b8bbca",
            width: "300px",
            margin: "0px",
            padding: `0 ${isLeft ? "5px" : "0"} 0 ${isLeft ? "0" : "5px"}`,
          }}
        >
          {/* SPACING */}
          <table role="presentation" cellPadding="0" cellSpacing="0" border="0">
            <tr>
              <td width="5" height="25"></td>
            </tr>
          </table>
          <table
            role="presentation"
            cellSpacing="0"
            cellPadding="0"
            border="0"
            style={{
              width: "300px",
            }}
          >
            <tr>
              <td>
                <table
                  role="presentation"
                  cellSpacing="0"
                  cellPadding="0"
                  border="0"
                  // style={{ marginBottom: "20px" }}
                >
                  <tr>
                    {topic && (
                      <td
                        style={{
                          borderRadius: "6px",
                          display: "inline-block",
                          fontSize: "12px",
                          lineHeight: "14px",
                          padding: "3px 0",
                          backgroundColor: "#141d50",
                          borderBottom: "1px solid #141d50",
                          borderTop: "1px solid #141d50",
                          color: "white",
                          // marginRight: "10px",
                        }}
                      >
                        <span>&nbsp;&nbsp;{topic[0].name}&nbsp;&nbsp;</span>
                      </td>
                    )}
                    <td width="8"></td>
                    {organization && organization.length > 0 && (
                      <td
                        style={{
                          borderRadius: "6px",
                          fontSize: "12px",
                          lineHeight: "14px",
                          padding: "3px 0",
                          border: "1px solid #141d50",
                          borderBottom: "1px solid #141d50",
                          color: "#141d50",
                        }}
                      >
                        <span>
                          &nbsp;&nbsp;{organization[0].name}&nbsp;&nbsp;
                        </span>
                      </td>
                    )}
                  </tr>
                </table>
                {/* SPACING */}{" "}
                <table
                  role="presentation"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                >
                  <tr>
                    <td width="5" height="20"></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "0",
                  verticalAlign: "baseline",
                }}
              >
                <p
                  style={{
                    color: "#141d50",
                    fontSize: "21px",
                    lineHeight: "29px",
                    margin: "0",
                  }}
                >
                  {title}
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "0",
                  verticalAlign: "baseline",
                }}
              >
                {/* SPACING */}{" "}
                <table
                  role="presentation"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                >
                  <tr>
                    <td width="5" height="16"></td>
                  </tr>
                </table>
                {documentToPlainTextString(reader.json).replace(
                  new RegExp(String.fromCharCode(160), "g"),
                  " "
                )}
                {/* SPACING */}{" "}
                <table
                  role="presentation"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                >
                  <tr>
                    <td width="5" height="16"></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  verticalAlign: "middle",
                  padding: "0",
                  paddingBottom: "50px",
                }}
              >
                <table
                  role="presentation"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                >
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <table
                        role="presentation"
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                      >
                        <tr>
                          <td style={{ verticalAlign: "middle" }}>
                            <a
                              href={generateLink(link)}
                              style={{
                                color: "#68c778",
                                marginTop: "30px",
                                verticalAlign: "middle",
                                textDecoration: "none",
                              }}
                            >
                              <span style={{ color: "#68c778" }}>
                                Weiterlesen
                              </span>
                            </a>
                          </td>
                          <td width="5"></td>
                          <td style={{ verticalAlign: "middle" }}>
                            <a
                              href={generateLink(link)}
                              style={{
                                color: "#68c778",
                                marginTop: "30px",
                                verticalAlign: "middle",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                src={getImage("arrow-right-solid")}
                                style={{
                                  marginLeft: "0px",
                                  verticalAlign: "middle",
                                  height: "20px",
                                  width: "20px",
                                }}
                                width="20"
                                height="20"
                              />
                            </a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </>
    );
  };

  const getImage = (title, isSVG = false) => {
    const image = images.find(image => image.title === `Newsletter - ${title}`);

    if (isSVG) {
      return image ? image.file.url : "";
    }

    return image ? image.fixed.src : "";
  };

  return (
    <>
      <SEO
        title={`${siteSettings.siteName} - ${number}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `html,

body {

    margin: 0 auto !important;

    padding: 0 !important;

    height: 100% !important;

    width: 100% !important;

}

p {
  margin-top: 0;
  margin-bottom: 30px;
}


/* Stop Outlook resizing small text. */

* {

    -ms-text-size-adjust: 100%;

}



/* Stop Outlook from adding extra spacing to tables. */

table,

td {

    mso-table-lspace: 0pt !important;

    mso-table-rspace: 0pt !important;

}



/* Use a better rendering method when resizing images in Outlook IE. */

img {

    -ms-interpolation-mode:bicubic;

}



/* Prevent Windows 10 Mail from underlining links. Styles for underlined links should be inline. */

a {

    text-decoration: none;

}
table {border-collapse:separate;}
a, a:link, a:visited {text-decoration: none; color: #00788a;}
a:hover {text-decoration: underline;}
h2,h2 a,h2 a:visited,h3,h3 a,h3 a:visited,h4,h5,h6,.t_cht {color:#000 !important;}
.ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
.ExternalClass {width: 100%;}
`,
        }}
      />
      <table
        border="0"
        cellspacing="0"
        cellpadding="0"
        width="100%"
        style={{ width: "100.0%", borderCollapse: "collapse" }}
      >
        <tbody>
          <tr>
            <td
              width="100%"
              valign="top"
              style={{
                width: "100.0%",
                padding: "0cm 0cm 0cm 0cm",
                backgroundColor: "#f2f2f2",
              }}
            >
              <div align="center">
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  width="100%"
                  style={{ width: "100.0%", borderCollapse: "collapse" }}
                >
                  <tbody>
                    <tr>
                      <td
                        valign="top"
                        style={{ background: "cover", backgroundSize: "cover" }}
                      >
                        <div
                          align="center"
                          style={{ backgroundColor: "#f2f2f2" }}
                        >
                          <table
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            width="640"
                            style={{
                              width: "640px",
                              borderCollapse: "collapse",
                              maxWidth: "640px",
                            }}
                          >
                            <tbody>
                              <tr
                                style={{
                                  background: "cover",
                                  backgroundSize: "cover",
                                }}
                              >
                                <td
                                  width="640"
                                  valign="top"
                                  style={{
                                    width: "640px",
                                    padding: "0cm 0cm 0cm 0cm",
                                    maxWidth: "640px",
                                  }}
                                >
                                  <table
                                    role="presentation"
                                    cellSpacing="0"
                                    cellPadding="0"
                                    border="0"
                                    style={{
                                      backgroundColor: "white",
                                      color: "#333333",
                                      fontFamily:
                                        "'MessinaSansWeb', 'Open Sans', sans-serif",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      margin: "0 auto",
                                      width: "640px",
                                      maxWidth: "640px",
                                    }}
                                    width="640"
                                  >
                                    <tr>
                                      <td>
                                        {/* HEADER */}
                                        <table
                                          role="presentation"
                                          cellSpacing="0"
                                          cellPadding="21"
                                          border="0"
                                        >
                                          <tr>
                                            <td>
                                              <img
                                                src={getImage(
                                                  "FriEnt Logo Impulse", true
                                                )}
                                                style={{
                                                  height: "43px",
                                                  width: "216px",
                                                }}
                                                alt="Frient Impulse"
                                                width="216"
                                                height="43"
                                              />
                                            </td>
                                            <td
                                              style={{
                                                width: "100%",
                                                textAlign: "right",
                                              }}
                                            >
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                style={{
                                                  marginLeft: "auto",
                                                  marginRight: 0,
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    style={{
                                                      backgroundColor:
                                                        "#68c778",
                                                      height: "39px",
                                                      textAlign: "center",
                                                      width: "150px",
                                                    }}
                                                  >
                                                    <a
                                                      href={url}
                                                      style={{
                                                        backgroundColor:
                                                          "#68c778",
                                                        borderRadius: "6px",
                                                        color: "white",
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      <table
                                                        width="100%"
                                                        role="presentation"
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        border="0"
                                                        style={{
                                                          marginLeft: "auto",
                                                          marginRight: 0,
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <td></td>
                                                        <tr>
                                                          <td width="20"></td>
                                                          <td
                                                            style={{
                                                              textAlign:
                                                                "right",
                                                            }}
                                                          >
                                                            <a
                                                              href={url}
                                                              style={{
                                                                backgroundColor:
                                                                  "#68c778",
                                                                borderRadius:
                                                                  "6px",
                                                                color: "white",
                                                                textDecoration:
                                                                  "none",
                                                              }}
                                                            >
                                                              <img
                                                                src={getImage(
                                                                  "Frient Favicon"
                                                                )}
                                                                style={{
                                                                  verticalAlign:
                                                                    "middle",
                                                                  height:
                                                                    "14px",
                                                                  width: "14px",
                                                                  marginRight:
                                                                    "0px",
                                                                }}
                                                                width="14"
                                                                height="14"
                                                                alt="Frient Logo"
                                                              />
                                                            </a>
                                                          </td>
                                                          <td width="5"></td>
                                                          <td
                                                            style={{
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            <a
                                                              href={url}
                                                              style={{
                                                                backgroundColor:
                                                                  "#68c778",
                                                                borderRadius:
                                                                  "6px",
                                                                color: "white",
                                                                textDecoration:
                                                                  "none",
                                                              }}
                                                            >
                                                              {" "}
                                                              <span
                                                                style={{
                                                                  marginLeft:
                                                                    "5px",
                                                                  color:
                                                                    "#FFFFFF",
                                                                }}
                                                              >
                                                                FriEnt.de
                                                              </span>
                                                            </a>
                                                          </td>
                                                          <td></td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                        {/* INTRO */}
                                        <table
                                          role="presentation"
                                          cellPadding="21"
                                          cellSpacing="0"
                                          border="0"
                                          style={{
                                            backgroundColor: "#e7e8ed",
                                          }}
                                        >
                                          <tr>
                                            <td>
                                              {/* SPACING */}
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                              >
                                                <tr>
                                                  <td
                                                    width="5"
                                                    height="25"
                                                  ></td>
                                                </tr>
                                              </table>
                                              <h5
                                                style={{
                                                  color: "#333333",
                                                  fontSize: "16px",
                                                  lineHeight: "19px",
                                                }}
                                              >
                                                {number}
                                              </h5>
                                              <p
                                                style={{
                                                  color: "#141d50",
                                                  fontSize: "28px",
                                                  lineHeight: "32px",
                                                  marginBottom: "30px",
                                                }}
                                              >
                                                {headline}
                                              </p>
                                              <p
                                                style={{
                                                  marginBottom: "30px",
                                                }}
                                              >
                                                {<Markdown>{body}</Markdown>}
                                              </p>
                                              {/* SPACING */}
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                              >
                                                <tr>
                                                  <td
                                                    width="5"
                                                    height="15"
                                                  ></td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                        {/* MENU */}
                                        <table
                                          role="presentation"
                                          cellSpacing="0"
                                          cellPadding="0"
                                          border="0"
                                          style={{
                                            backgroundColor: "#141d50",
                                            color: "white",
                                            height: "86px",
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                height: "86px",
                                                width: "100%",
                                              }}
                                            >
                                              <span>
                                                <a
                                                  href="#impuls"
                                                  style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Impuls
                                                </a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </span>
                                              <span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a
                                                  href="#themen"
                                                  style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Themen
                                                </a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </span>
                                              <span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a
                                                  href="#stimmen"
                                                  style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Stimmen
                                                </a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </span>
                                              <span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a
                                                  href="#tipps"
                                                  style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Tipps &amp; Infos
                                                </a>
                                              </span>
                                            </td>
                                          </tr>
                                        </table>
                                        {/* IMPULS */}
                                        <table
                                          role="presentation"
                                          cellPadding="21"
                                          cellSpacing="0"
                                          border="0"
                                          style={{
                                            borderBottom: "1px solid #b8bbca",
                                          }}
                                        >
                                          <tr>
                                            <td id="impuls">
                                              <a name="impuls">
                                                <br />
                                              </a>
                                              <img
                                                width="600"
                                                src={
                                                  impulsArticle.imageLandscape
                                                    .imageLandscape.fixed.src
                                                }
                                                alt="placeholder"
                                                style={{
                                                  width: "100%",
                                                }}
                                              />
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                              >
                                                <tr>
                                                  <td
                                                    width="5"
                                                    height="30"
                                                  ></td>
                                                </tr>
                                              </table>
                                              <h5
                                                style={{
                                                  color: "#333333",
                                                  fontSize: "16px",
                                                  lineHeight: "19px",
                                                  marginBottom: "0px",
                                                  marginTop: "0px",
                                                }}
                                              >
                                                Impuls
                                              </h5>
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                              >
                                                <tr>
                                                  <td
                                                    width="5"
                                                    height="30"
                                                  ></td>
                                                </tr>
                                              </table>
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                style={{
                                                  marginBottom: "0px",
                                                }}
                                              >
                                                <tr>
                                                  <td>
                                                    <p
                                                      style={{
                                                        color: "#141d50",
                                                        fontSize: "24px",
                                                        lineHeight: "29px",
                                                        marginBottom: "0px",
                                                        marginTop: "0px",
                                                      }}
                                                    >
                                                      {impulsArticle.title}
                                                    </p>
                                                    {impulsArticle.subtitle && (
                                                      <>
                                                        {/* SPACING */}
                                                        <table
                                                          role="presentation"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          border="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              width="5"
                                                              height="30"
                                                            ></td>
                                                          </tr>
                                                        </table>
                                                        <p
                                                          style={{
                                                            fontSize: "20px",
                                                            lineHeight: "24px",
                                                            marginBottom: "0px",
                                                            marginTop: "0px",
                                                          }}
                                                        >
                                                          {
                                                            impulsArticle.subtitle
                                                          }
                                                        </p>
                                                      </>
                                                    )}
                                                    {/* SPACING */}
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                    >
                                                      <tr>
                                                        <td
                                                          width="5"
                                                          height="16"
                                                        ></td>
                                                      </tr>
                                                    </table>
                                                    {documentToReactComponents(
                                                      impulsArticle.reader.json
                                                    )}
                                                    {/* SPACING */}
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                    >
                                                      <tr>
                                                        <td
                                                          width="5"
                                                          height="16"
                                                        ></td>
                                                      </tr>
                                                    </table>
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                    >
                                                      <tr>
                                                        <td
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        >
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  verticalAlign:
                                                                    "middle",
                                                                }}
                                                              >
                                                                <a
                                                                  href={generateLink(
                                                                    impulsArticle.link
                                                                  )}
                                                                  style={{
                                                                    color:
                                                                      "#68c778",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    textDecoration:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "#68c778",
                                                                    }}
                                                                  >
                                                                    Zum
                                                                    Leitartikel
                                                                  </span>
                                                                </a>
                                                              </td>
                                                              <td width="5"></td>
                                                              <td
                                                                style={{
                                                                  verticalAlign:
                                                                    "middle",
                                                                }}
                                                              >
                                                                <a
                                                                  href={generateLink(
                                                                    impulsArticle.link
                                                                  )}
                                                                  style={{
                                                                    color:
                                                                      "#68c778",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    textDecoration:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={getImage(
                                                                      "arrow-right-solid"
                                                                    )}
                                                                    style={{
                                                                      marginLeft:
                                                                        "0px",
                                                                      verticalAlign:
                                                                        "middle",
                                                                      height:
                                                                        "20px",
                                                                      width:
                                                                        "20px",
                                                                    }}
                                                                    width="20"
                                                                    height="20"
                                                                  />
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                    {/* SPACING */}
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                    >
                                                      <tr>
                                                        <td
                                                          width="5"
                                                          height="50"
                                                        ></td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                        {/* THEMEN */}
                                        <table
                                          role="presentation"
                                          cellSpacing="0"
                                          border="0"
                                          style={{
                                            backgroundColor: "#e7e8ed",
                                            width: "100%",
                                          }}
                                          id="themen"
                                          width="600"
                                        >
                                          <tr>
                                            {/* SPACING */}
                                            <td width="21" height="30"></td>
                                            <td
                                              style={{
                                                padding: "0px",
                                              }}
                                            >
                                              <a name="themen">
                                                <br />
                                              </a>
                                              <table
                                                role="presentation"
                                                cellSpacing="0"
                                                border="0"
                                                id="themen"
                                              >
                                                <tr>
                                                  <td height="45"></td>
                                                </tr>
                                              </table>
                                              <h5
                                                style={{
                                                  color: "#333333",
                                                  fontSize: "16px",
                                                  lineHeight: "19px",
                                                  margin: "0px",
                                                }}
                                              >
                                                Themen
                                              </h5>
                                            </td>
                                            {/* SPACING */}
                                            <td width="21" height="30"></td>
                                          </tr>
                                          <tr>
                                            {/* SPACING */}
                                            <td width="21" height="30"></td>
                                            <td
                                              style={{
                                                padding: "0px",
                                              }}
                                            >
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                style={{
                                                  borderCollapse: "collapse",
                                                  padding: "0px",
                                                  margin: "0px",
                                                  width: "100%",
                                                }}
                                              >
                                                {themenArticles.map(
                                                  (article, index) =>
                                                    index % 2 !== 0 && (
                                                      <tr
                                                        id="themenArticle"
                                                        style={{
                                                          minHeight: "1px",
                                                        }}
                                                        key={index}
                                                      >
                                                        {renderThemenArticle({
                                                          ...themenArticles[
                                                            index - 1
                                                          ],
                                                          isLast:
                                                            index ===
                                                            themenArticles.length -
                                                              1,
                                                          isLeft: true,
                                                        })}
                                                        {renderThemenArticle({
                                                          ...article,
                                                          isLast:
                                                            index ===
                                                            themenArticles.length -
                                                              1,
                                                          isRight: true,
                                                        })}
                                                      </tr>
                                                    )
                                                )}
                                              </table>
                                            </td>
                                            {/* SPACING */}
                                            <td width="21" height="30"></td>
                                          </tr>
                                        </table>
                                        {/* STIMMEN */}
                                        <table
                                          role="presentation"
                                          cellPadding="21"
                                          cellSpacing="0"
                                          border="0"
                                        >
                                          <tr>
                                            <td id="stimmen">
                                              <a name="stimmen">
                                                <br />
                                              </a>
                                              {/* SPACING */}
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                              >
                                                <tr>
                                                  <td
                                                    width="5"
                                                    height="30"
                                                  ></td>
                                                </tr>
                                              </table>
                                              <h5
                                                style={{
                                                  color: "#333333",
                                                  fontSize: "16px",
                                                  lineHeight: "19px",
                                                  marginBottom: "0px",
                                                  marginTop: "0px",
                                                }}
                                              >
                                                Stimmen
                                              </h5>
                                              {stimmenArticles.map(
                                                (article, index) => (
                                                  <table
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    key={index}
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #b8bbca",
                                                    }}
                                                  >
                                                    <tr>
                                                      <td>
                                                        {/* SPACING */}
                                                        <table
                                                          role="presentation"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          border="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              width="5"
                                                              height="30"
                                                            ></td>
                                                          </tr>
                                                        </table>
                                                        <p
                                                          style={{
                                                            color: "#141d50",
                                                            fontSize: "24px",
                                                            lineHeight: "29px",
                                                            marginBottom: "0px",
                                                            marginTop: "0px",
                                                          }}
                                                        >
                                                          {article.title}
                                                        </p>
                                                        {article.subtitle && (
                                                          <>
                                                            {/* SPACING */}
                                                            <table
                                                              role="presentation"
                                                              cellPadding="0"
                                                              cellSpacing="0"
                                                              border="0"
                                                            >
                                                              <tr>
                                                                <td
                                                                  width="5"
                                                                  height="30"
                                                                ></td>
                                                              </tr>
                                                            </table>
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "20px",
                                                                lineHeight:
                                                                  "24px",
                                                                marginBottom:
                                                                  "0px",
                                                                marginTop:
                                                                  "0px",
                                                              }}
                                                            >
                                                              {article.subtitle}
                                                            </p>
                                                          </>
                                                        )}
                                                        {/* SPACING */}
                                                        <table
                                                          role="presentation"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          border="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              width="5"
                                                              height="16"
                                                            ></td>
                                                          </tr>
                                                        </table>
                                                        {documentToReactComponents(
                                                          article.reader.json
                                                        )}
                                                        {/* SPACING */}
                                                        <table
                                                          role="presentation"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          border="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              width="5"
                                                              height="16"
                                                            ></td>
                                                          </tr>
                                                        </table>
                                                        <table
                                                          role="presentation"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          border="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              style={{
                                                                verticalAlign:
                                                                  "middle",
                                                              }}
                                                            >
                                                              <table
                                                                role="presentation"
                                                                cellPadding="0"
                                                                cellSpacing="0"
                                                                border="0"
                                                              >
                                                                <tr>
                                                                  <td
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      href={generateLink(
                                                                        article.link
                                                                      )}
                                                                      style={{
                                                                        color:
                                                                          "#68c778",
                                                                        marginTop:
                                                                          "30px",
                                                                        verticalAlign:
                                                                          "middle",
                                                                        textDecoration:
                                                                          "none",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#68c778",
                                                                        }}
                                                                      >
                                                                        Weiterlesen
                                                                      </span>
                                                                    </a>
                                                                  </td>
                                                                  <td width="5"></td>
                                                                  <td
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      href={generateLink(
                                                                        article.link
                                                                      )}
                                                                      style={{
                                                                        color:
                                                                          "#68c778",
                                                                        marginTop:
                                                                          "30px",
                                                                        verticalAlign:
                                                                          "middle",
                                                                        textDecoration:
                                                                          "none",
                                                                      }}
                                                                    >
                                                                      <img
                                                                        src={getImage(
                                                                          "arrow-right-solid"
                                                                        )}
                                                                        style={{
                                                                          marginLeft:
                                                                            "0px",
                                                                          verticalAlign:
                                                                            "middle",
                                                                          height:
                                                                            "20px",
                                                                          width:
                                                                            "20px",
                                                                        }}
                                                                        width="20"
                                                                        height="20"
                                                                      />
                                                                    </a>
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                              {/* SPACING */}
                                                              <table
                                                                role="presentation"
                                                                cellPadding="0"
                                                                cellSpacing="0"
                                                                border="0"
                                                              >
                                                                <tr>
                                                                  <td
                                                                    width="5"
                                                                    height="50"
                                                                  ></td>
                                                                </tr>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                )
                                              )}
                                            </td>
                                          </tr>
                                        </table>
                                        {/* TIPPS */}
                                        <table
                                          role="presentation"
                                          cellPadding="21"
                                          cellSpacing="0"
                                          border="0"
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                paddingTop: "35px",
                                              }}
                                              id="tipps"
                                            >
                                              <a name="tipps">
                                                {/* SPACING */}
                                                <table
                                                  role="presentation"
                                                  cellPadding="0"
                                                  cellSpacing="0"
                                                  border="0"
                                                >
                                                  <tr>
                                                    <td
                                                      width="1"
                                                      height="0"
                                                    ></td>
                                                  </tr>
                                                </table>
                                              </a>
                                              <h5
                                                style={{
                                                  color: "#333333",
                                                  fontSize: "16px",
                                                  lineHeight: "19px",
                                                  marginBottom: "0px",
                                                  marginTop: "0px",
                                                }}
                                              >
                                                Tipps & Infos
                                              </h5>
                                              {tippsArticles.map(
                                                (tippsArticle, index) => (
                                                  <>
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                      key={index}
                                                    >
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #b8bbca",
                                                          }}
                                                        >
                                                          {/* SPACING */}
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                width="5"
                                                                height="30"
                                                              ></td>
                                                            </tr>
                                                          </table>
                                                          <p
                                                            style={{
                                                              color: "#141d50",
                                                              fontSize: "24px",
                                                              lineHeight:
                                                                "29px",
                                                              marginBottom:
                                                                "0px",
                                                              marginTop: "0px",
                                                            }}
                                                          >
                                                            {tippsArticle.title}
                                                          </p>
                                                          {tippsArticle.subtitle && (
                                                            <>
                                                              {/* SPACING */}{" "}
                                                              <table
                                                                role="presentation"
                                                                cellPadding="0"
                                                                cellSpacing="0"
                                                                border="0"
                                                              >
                                                                <tr>
                                                                  <td
                                                                    width="5"
                                                                    height="30"
                                                                  ></td>
                                                                </tr>
                                                              </table>
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "20px",
                                                                  lineHeight:
                                                                    "24px",
                                                                  marginBottom:
                                                                    "0px",
                                                                  marginTop:
                                                                    "0px",
                                                                }}
                                                              >
                                                                {
                                                                  tippsArticle.subtitle
                                                                }
                                                              </p>
                                                            </>
                                                          )}
                                                          {/* SPACING */}{" "}
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                width="5"
                                                                height="16"
                                                              ></td>
                                                            </tr>
                                                          </table>
                                                          {documentToReactComponents(
                                                            tippsArticle.reader
                                                              .json
                                                          )}
                                                          {/* SPACING */}{" "}
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                width="5"
                                                                height="16"
                                                              ></td>
                                                            </tr>
                                                          </table>
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  verticalAlign:
                                                                    "middle",
                                                                }}
                                                              >
                                                                <tr>
                                                                  <td
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      href={generateLink(
                                                                        tippsArticle.link
                                                                      )}
                                                                      style={{
                                                                        color:
                                                                          "#68c778",
                                                                        marginTop:
                                                                          "30px",
                                                                        verticalAlign:
                                                                          "middle",
                                                                        textDecoration:
                                                                          "none",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#68c778",
                                                                        }}
                                                                      >
                                                                        Weiterlesen
                                                                      </span>
                                                                    </a>
                                                                  </td>
                                                                  <td width="5"></td>
                                                                  <td
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      href={generateLink(
                                                                        tippsArticle.link
                                                                      )}
                                                                      style={{
                                                                        color:
                                                                          "#68c778",
                                                                        marginTop:
                                                                          "30px",
                                                                        verticalAlign:
                                                                          "middle",
                                                                        textDecoration:
                                                                          "none",
                                                                      }}
                                                                    >
                                                                      <img
                                                                        src={getImage(
                                                                          "arrow-right-solid"
                                                                        )}
                                                                        style={{
                                                                          marginLeft:
                                                                            "0px",
                                                                          verticalAlign:
                                                                            "middle",
                                                                          height:
                                                                            "20px",
                                                                          width:
                                                                            "20px",
                                                                        }}
                                                                        width="20"
                                                                        height="20"
                                                                      />
                                                                    </a>
                                                                  </td>
                                                                </tr>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                          {/* SPACING */}
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                width="5"
                                                                height="50"
                                                              ></td>
                                                            </tr>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </>
                                                )
                                              )}
                                            </td>
                                          </tr>
                                        </table>
                                        {/* MEMBERS */}
                                        <table
                                          role="presentation"
                                          cellPadding="21"
                                          cellSpacing="0"
                                          border="0"
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                margin: "9px 0",
                                                textAlign: "center",
                                              }}
                                            >
                                              {[
                                                "Members Brot für die Welt",
                                                "Members BMZ",
                                                "Members GIZ",
                                                "Members FES",
                                                "Members Misereor",
                                                "Members ZFD",
                                                "Members PZK",
                                                "Members Berghof",
                                              ].map(member => (
                                                <img
                                                  src={getImage(member)}
                                                  alt="Members logos"
                                                  style={{ width: "25%" }}
                                                  width="25%"
                                                />
                                              ))}
                                            </td>
                                          </tr>
                                        </table>

                                        {/* FOOTER */}
                                        <table
                                          role="presentation"
                                          cellPadding="21"
                                          cellSpacing="0"
                                          border="0"
                                          style={{
                                            backgroundColor: "#e7e8ed",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            width: "100%",
                                          }}
                                        >
                                          <tr>
                                            <td>
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                style={{
                                                  borderBottom:
                                                    "1px solid #b8bbca",
                                                  paddingTop: "30px",
                                                  width: "100%",
                                                }}
                                              >
                                                <tr>
                                                  <td>
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                      style={{ width: "100%" }}
                                                    >
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "40%",
                                                          }}
                                                        >
                                                          <img
                                                            src={getImage(
                                                              "Frient Logo PNG"
                                                            )}
                                                            style={{
                                                              width: "94px",
                                                            }}
                                                            alt="FriEnt Logo"
                                                            width="94"
                                                          />
                                                          {/* SPACING */}
                                                          <table
                                                            role="presentation"
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            border="0"
                                                          >
                                                            <tr>
                                                              <td
                                                                width="5"
                                                                height="30"
                                                              ></td>
                                                            </tr>
                                                          </table>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "60%",
                                                            textAlign: "right",
                                                            verticalAlign:
                                                              "baseline",
                                                          }}
                                                        >
                                                          <table
                                                            style={{
                                                              marginLeft:
                                                                "auto",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td width="38">
                                                                <a
                                                                  href={generateLink(
                                                                    "/artikel"
                                                                  )}
                                                                  target="_blank"
                                                                >
                                                                  <img
                                                                    src={getImage(
                                                                      "Blog Icon Round"
                                                                    )}
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                      width:
                                                                        "38px",
                                                                      height:
                                                                        "38px",
                                                                    }}
                                                                    height="38"
                                                                    width="38"
                                                                    alt="Blog"
                                                                  />
                                                                </a>
                                                              </td>
                                                              <td width="13"></td>
                                                              <td width="38">
                                                                <a
                                                                  href={
                                                                    YOUTUBE_CHANNEL_URL
                                                                  }
                                                                  target="_blank"
                                                                >
                                                                  <img
                                                                    src={getImage(
                                                                      "Youtube Icon Round"
                                                                    )}
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                      width:
                                                                        "38px",
                                                                      height:
                                                                        "38px",
                                                                    }}
                                                                    height="38"
                                                                    width="38"
                                                                    alt="Youtube"
                                                                  />
                                                                </a>
                                                              </td>
                                                              <td width="13"></td>
                                                              <td width="38">
                                                                <a
                                                                  href="https://twitter.com/frient_news"
                                                                  target="_blank"
                                                                >
                                                                  <img
                                                                    src={getImage(
                                                                      "Twitter Icon Round"
                                                                    )}
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                      width:
                                                                        "38px",
                                                                      height:
                                                                        "38px",
                                                                    }}
                                                                    height="38"
                                                                    width="38"
                                                                    alt="Twitter"
                                                                  />
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {documentToReactComponents(
                                                      footerAddress.json,
                                                      options
                                                    )}
                                                    {/* SPACING */}
                                                    <table
                                                      role="presentation"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      border="0"
                                                    >
                                                      <tr>
                                                        <td
                                                          width="5"
                                                          height="30"
                                                        ></td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                              <table
                                                role="presentation"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                style={{
                                                  marginTop: "15px",
                                                  width: "100%",
                                                }}
                                              >
                                                <tr>
                                                  <td style={{ width: "60%" }}>
                                                    <span
                                                      style={{
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      &copy; FriEnt{" "}
                                                      {new Date().getFullYear()}
                                                    </span>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <a
                                                      href={generateLink(
                                                        "/datenschutz"
                                                      )}
                                                      style={{
                                                        color: "#333333",
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      Datenschutz
                                                    </a>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <a
                                                      href={generateLink(
                                                        "/impressum"
                                                      )}
                                                      style={{
                                                        color: "#333333",
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      Impressum
                                                    </a>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>{" "}
    </>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String!, $node_locale: String!) {
    newsletter: contentfulNewsletter(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      number
      headline
      body {
        body
      }
      impulsArticle {
        title
        articleDetail {
          title: articleTitle
          subtitle: articleSubtitle
          focusOfWork {
            name
          }
          topic {
            name
          }
          reader: articleReader {
            json
          }
          imageLandscape {
            imageLandscape {
              fixed(width: 1000, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
      stimmenArticles {
        title
        articleDetail {
          title: articleTitle
          subtitle: articleSubtitle
          focusOfWork {
            name
          }
          topic {
            name
          }
          reader: articleReader {
            json
          }
        }
      }
      themenArticles {
        title
        articleDetail {
          title: articleTitle
          subtitle: articleSubtitle
          focusOfWork {
            name
          }
          topic {
            name
          }
          organization {
            name
          }
          reader: articleReader {
            json
          }
        }
      }
      tippsArticles {
        title
        articleDetail {
          title: articleTitle
          subtitle: articleSubtitle
          focusOfWork {
            name
          }
          topic {
            name
          }
          reader: articleReader {
            json
          }
        }
      }
    }
    images: allContentfulAsset(
      filter: {
        title: { regex: "/Newsletter - /" }
        node_locale: { eq: $node_locale }
      }
    ) {
      edges {
        node {
          fixed {
            src
          }
          file {
            url
          }
          title
        }
      }
    }
  }
`;

export default NewsletterDetail;
